import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import Illustration from '../components/illustration'
import SectionContact from '../components/section-contact'
import ButtonLink from '../components/button-link'

export default function WorkPage({ data, location }) {
  const { allWordpressWpClient, wordpressPage } = data
  const { acf } = wordpressPage
  const { work_page_info, seo, open_graph, twitter, contact_section } = acf

  return (
    <Layout location={location.pathname}>
      <Helmet
        title={seo.title}
        meta={[
          { name: 'description', content: seo.description },
          { name: 'og:title', content: open_graph.title },
          { name: 'og:description', content: open_graph.description },
          { name: 'og:image', content: open_graph.image.source_url },
          { name: 'og:url', content: 'https://www.modinfinity.com/work' },
          { name: 'twitter:title', content: twitter.title },
          { name: 'twitter:description', content: twitter.description },
          { name: 'twitter:image', content: twitter.image.source_url },
          { name: 'twitter:card', content: twitter.card_size },
        ]}
      />
      <section className="container mx-auto px-3 md:px-6 py-8 md:py-16 md:flex md:flex-row-reverse lg:flex-row md:items-center">
        <div className="sm:w-3/5 md:w-1/2 sm:mx-auto">
          <Illustration illustration={work_page_info.illustration} />
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <h1 className="font-black text-4xl md:text-5xl lg:text-6xl xxl:text-7xl text-indigo-darkest text-center md:text-left lg:text-right">
            {work_page_info.title}
          </h1>
          {work_page_info.copy && <p>{work_page_info.copy}</p>}
        </div>
      </section>
      <section className="container mx-auto px-2 md:px-6 py-8">
        {allWordpressWpClient.edges.map(({ node }) => (
          <div
            id={node.id}
            className="my-6 md:my-16 lg:my-24 xl:my-30 flex flex-col-reverse lg:flex-row lg:items-center"
          >
            <div className="md:w-3/4 lg:w-3/5 md:mx-auto lg:pr-3 py-6 md:pt-12">
              <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-indigo-darkest font-black text-center lg:text-left">
                {node.acf.work_info.title}
              </h2>
              <p className="py-3 xl:py-8 text-xl text-indigo-darkest font-light leading-normal">
                {node.acf.work_info.description}
              </p>
              <div className="my-2 flex justify-center lg:justify-start items-center">
                <ButtonLink
                  text="View Project"
                  url={`/work/${node.slug}`}
                  color="indigo-darkest"
                  inverse_color="indigo-lightest"
                />
              </div>
            </div>
            <div className="md:w-3/5 lg:2/5 md:mx-auto">
              <Img
                fluid={
                  node.acf.work_info.main_image.localFile.childImageSharp.fluid
                }
                alt={node.acf.work_info.main_image.alt_text}
              />
            </div>
          </div>
        ))}
      </section>
      <SectionContact contact={contact_section} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWordpressWpClient(
      filter: { acf: { work_info: { featured: { eq: "yes" } } } }
      sort: { fields: acf___work_info___order, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          acf {
            work_info {
              title
              description
              main_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1024) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(slug: { eq: "work" }) {
      acf {
        work_page_info {
          title
          copy
          illustration
        }
        contact_section {
          title
          call_to_action
          illustration
          illustration_position
        }
        seo {
          title
          description
        }
        open_graph {
          title
          description
          image {
            source_url
          }
        }
        twitter {
          title
          description
          card_size
          image {
            source_url
          }
        }
      }
    }
  }
`
