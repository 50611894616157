import React from 'react'
import { Link } from 'gatsby'

export default function ButtonLink({ text, url, color, inverse_color }) {
  const classes = `text-${color} hover:text-${inverse_color} border-${color} hover:bg-${color} p-3 text-lg no-underline font-bold border-2 transition-bg`

  return (
    <Link className={classes} to={url}>
      {text}
    </Link>
  )
}
