import React from 'react'

import working_together from '../images/ud_co_workers.svg'
import user_experience from '../images/ud_user_experience.svg'
import crafted from '../images/ud_crafted.svg'
import conversation from '../images/ud_conversation.svg'
import designer from '../images/ud_designer.svg'
import mobile_testing from '../images/ud_mobile_testing.svg'
import on_the_office from '../images/ud_on_the_office.svg'
import programming from '../images/ud_programming.svg'
import prototyping_process from '../images/ud_prototyping_process.svg'
import to_do_list from '../images/ud_to_do_list.svg'
import usability_testing from '../images/ud_usability_testing.svg'
import wireframing from '../images/ud_wireframing.svg'

export default function Illustration({ illustration }) {
  let illustration_img = working_together
  let illustration_alt = ''

  switch (illustration) {
    case 'working_together':
      illustration_img = working_together
      illustration_alt =
        'Illustration of two co-workers sitting near a large window working on a single computer'
      break
    case 'user_experience':
      illustration_img = user_experience
      illustration_alt =
        'Illustration of a woman hand-drawing user interface screens for an app'
      break
    case 'crafted':
      illustration_img = crafted
      illustration_alt =
        'Illustration of a man interacting with a dark indigo software dashboard'
      break
    case 'conversation':
      illustration_img = conversation
      illustration_alt =
        'Illustration of two people having a conversation in a modern office with big glass windows and plants nearby'
      break
    case 'designer':
      illustration_img = designer
      illustration_alt =
        'Illustration of a man with a beard sitting in front of a computer designing with a plant and hot cup of coffee near by'
      break
    case 'mobile_testing':
      illustration_img = mobile_testing
      illustration_alt =
        'Illustration of a group of three people standing and taking notes near a giant smart phone laying on the ground'
      break
    case 'on_the_office':
      illustration_img = on_the_office
      illustration_alt =
        'Illustration of a person casually sitting on a modern rounded desk'
      break
    case 'programming':
      illustration_img = programming
      illustration_alt =
        'Illustration of a person sitting a desk with six monitors while programming'
      break
    case 'prototyping_process':
      illustration_img = prototyping_process
      illustration_alt =
        'Illustration of a woman drawing a user interface on a whiteboard and a tablet in the background prototyping her design'
      break
    case 'to_do_list':
      illustration_img = to_do_list
      illustration_alt =
        'Illustration of a woman checking off the final item of her todo list'
      break
    case 'usability_testing':
      illustration_img = usability_testing
      illustration_alt =
        'Illustration of two people interacting with a life sized verson of a website they are building'
      break
    case 'wireframing':
      illustration_img = wireframing
      illustration_alt =
        'Illustration of a woman designing a website in front of a large computer'
      break
    default:
      illustration_img = working_together
      illustration_alt =
        'Illustration of two co-workers sitting near a large window working on a single computer'
      break
  }

  return <img src={illustration_img} alt={illustration_alt} />
}
